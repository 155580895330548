//@ts-nocheck
import qs from 'qs';
import * as  UUID from 'uuid';

export const tgUtils = {
    decodeTelegramUrlParameters: function decodeTelegramUrlParameters(encodedParameters, isObject = true) {
        const decodedParams = encodedParameters
            .replaceAll('--', '%')
            .replaceAll('__', '=')
            .replaceAll('-', '&')
            .replaceAll('%5F', '_')
            .replaceAll('%2D', '-')
            .replaceAll('%2E', '.');
        if (isObject) return qs.parse(decodedParams);
        return decodedParams;
    },
    stringify: function stringify(obj) {
        const params = new URLSearchParams();

        function buildParams(prefix, value) {
            if (Array.isArray(value)) {
                value.forEach((v, i) => {
                    if (/\[\]$/.test(prefix)) {
                        params.append(prefix, v);
                    } else {
                        buildParams(`${prefix}[${typeof v === 'object' ? i : ''}]`, v);
                    }
                });
            } else if (typeof value === 'object') {
                for (const key in value) {
                    buildParams(`${prefix}[${key}]`, value[key]);
                }
            } else {
                params.append(prefix, value);
            }
        }

        for (const key in obj) {
            buildParams(key, obj[key]);
        }

        return params.toString();
    },
    encodeTelegramUrlParameters: function encodeTelegramUrlParameters(parameters, isObject = true) {

        if (isObject) {

            parameters = tgUtils.stringify(parameters)
            //  parameters = qs.stringify(parameters)

        }
        return parameters
            .replaceAll('.', '%2E')
            .replaceAll('-', '%2D')
            .replaceAll('_', '%5F')
            .replaceAll('&', '-')
            .replaceAll('=', '__')
            .replaceAll('%', '--');
    },
    opendeepLink: function opendeepLink(paramsStr, {
        domain="UXUYbot",
        appname="app",
    }) {
        var protoUrl = `tg:\/\/resolve?domain=${domain}&appname=${appname}&startapp=${paramsStr}`;
        if (true) {
            var iframeContEl = document.getElementById('tgme_frame_cont') || document.body;
            var iframeEl = document.createElement('iframe');
            iframeContEl.appendChild(iframeEl);
            var pageHidden = false;
            window.addEventListener('visibilitychange', function() {
                    pageHidden = document.hidden || document.webkitHidden || document.mozHidden || document.msHidden;
            }, false);
            window.addEventListener('pagehide', function() {
                pageHidden = true;
            }, false);
            window.addEventListener('blur', function() {
                pageHidden = true;
            }, false);
            if (iframeEl !== null) {
                iframeEl.src = protoUrl;
            }
            !false && setTimeout(function() {
                if (!pageHidden) {
                    window.location = protoUrl;
                }
            }, 2000);
        } else if (protoUrl) {
            setTimeout(function() {
                window.location = protoUrl;
            }, 100);
        }
    }
}

export function getUUid() {
    return UUID.v4();
};


export default {}
